// Check Mobile
export const browser = {
  versions: (function () {
    let u = navigator.userAgent;
    return {
      trident: u.indexOf("Trident") > -1, //IE
      presto: u.indexOf("Presto") > -1, //opera
      webKit: u.indexOf("AppleWebKit") > -1, //google
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //firefox
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), // is mobile
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios mobile
      android: u.indexOf("Android") > -1 || u.indexOf("Adr") > -1, //android mobile
      iPhone: u.indexOf("iPhone") > -1, // iPhone browser or QQHD browser
      iPad: u.indexOf("iPad") > -1, // iPad
      webApp: u.indexOf("Safari") == -1, // web Safari
      weixin: u.indexOf("MicroMessenger") > -1, // weixin
      qq: u.match(/\sQQ/i) == " qq", // QQ
    };
  })(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase(),
};
