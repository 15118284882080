<template>
  <div :class="['login-box']">
    <div v-if="isLoading"></div>
    <div :class="[{ 'banner-box': !isMobile }]">
      <div
        :class="[{ 'login-content': isMobile }, { 'banner-left': !isMobile }]"
      >
        <div class="title-login">{{ $t("login.sloganTitle") }}</div>
        <div :class="[{ 'btn-login': isMobile }]">
          <v-btn
            width="100%"
            height="58px"
            color="#539dd7"
            :elevation="vDefaultElevation"
            @click="connectMetamaskBtnFun"
            class="login-btn"
          >
            {{ $t("login.connectWallet") }}
          </v-btn>
        </div>
      </div>
      <!--      <div class="banner-right" v-if="!isMobile">-->
      <!--        <img class="login-banner" src="/static/image/login_banner.png" />-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Login from "@/views/script/login.js";
import { getProvider } from "@/utils/connet";
export default {
  name: "Login",
  mixins: [Login],
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
      vDefaultElevation: (state) => state.system.vDefaultElevation,
      token: (state) => state.user.token,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  data() {
    return {};
  },
  created() {
    getProvider();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.title-login {
  font-family: sans-serif;
  max-width: 600px;
  line-height: 50px;
  color: #539dd7;
  font-size: 36px;
  font-weight: bold;
  white-space: pre-line;
  margin-bottom: 10vh;
}

.login-box {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  background: url("/static/image/logo_dark3.png") repeat;
  background-size: 20%;
}

.banner-box {
  width: 1000px;
  overflow: hidden;
  margin: 0 auto;
}

.banner-left {
  float: left;
}

.banner-right {
  float: right;
}

.banner-left,
.banner-right {
  position: relative;
  margin-top: 10vh;
}

.login-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 10vh;
}

.login-banner {
  width: 400px;
  height: auto;
}

.btn-login {
  position: absolute;
  width: 90%;
  left: 5%;
  bottom: 10vh;
}

.padding-64 {
  padding: 64px;
}

.login-btn {
  font-size: 22px;
  color: #ffffff;
}

@media only screen and (min-width: 768px) {
  .title-login {
    font-size: 46px;
    line-height: 70px;
    font-weight: 300;
  }
}
</style>
