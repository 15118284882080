import { Connect2MetamaskProcess, get_sign_message } from "@/utils/connet";
import { get_nonce, login_from_sign } from "@/api/user";
import { browser } from "@/utils/utils";

export default {
  data() {
    return {
      isLoading: false,
      localWalletAddress: "",
    };
  },
  methods: {
    connectMetamaskBtnFun() {
      console.log("browser", browser.versions);
      Connect2MetamaskProcess()
        .then((res) => {
          if (res && res.length > 0) {
            this.localWalletAddress = res;
            this.getNonce();
          } else {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: this.$t("login.changeNetAgain"),
            });
          }
        })
        .catch((err) => {
          console.log("err connect_meta_mask:", err);
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: this.$t("login.changeNetAgain"),
          });
        });
    },
    getNonce() {
      this.isLoading = true;
      if (this.localWalletAddress.length === 0) {
        this.isLoading = false;
        this.$store.commit("snackbar/setSnackbar", {
          show: true,
          text: this.$t("login.loginBeforeTip"),
        });
        return;
      }
      get_nonce({
        address: this.localWalletAddress,
      })
        .then((res) => {
          if (res?.message?.length > 0) {
            this.createSignMessage(res.message);
          }
        })
        .catch((err) => {
          console.log("err get_nonce:", err);
          this.isLoading = false;
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: this.$t("network.refreshAgain"),
          });
        });
    },
    createSignMessage(message = "") {
      get_sign_message(message)
        .then((res) => {
          this.handleLoginFromSign(res);
        })
        .catch((err) => {
          console.log("err get_sign_message:", err);
          this.isLoading = false;
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: this.$t("network.refreshAgain"),
          });
        });
    },
    handleLoginFromSign(sign = "") {
      login_from_sign({
        address: this.localWalletAddress,
        sign: sign,
      })
        .then(() => {
          window.isFirstLogin = true;
          this.$store.commit("user/walletAddress", this.localWalletAddress);
          this.$router.push({ path: "/" });
        })
        .catch((err) => {
          console.log("err login_from_sign:", err);
          this.isLoading = false;
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: this.$t("network.refreshAgain"),
          });
        });
    },
  },
};
